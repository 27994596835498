import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { HtmlContentLoader, TvPagination } from '@powdr/components';
import { formatDateFromEpoch } from '@powdr/utils';

import {
  AlertIcon,
  AlertQrCodeWrapper,
  Message,
  QrCode,
  StyledTvAlerts, Timestamp, TimestampDate, TimestampIconWrapper, TimestampTime,
} from './styles';

export const TvAlerts = ({
  className,
  displayTime,
  alerts,
  alertQrCode,
  skip,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [alertsReady, setAlertsReady] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const alertRotationRef = useRef();

  const getTimestampDate = (t) => {
    const dateObj = formatDateFromEpoch(t.updated);
    return `${dateObj.month.long} ${dateObj.day.num}, ${dateObj.year}`;
  };

  const getTimestampTime = (t) => {
    const dateObj = formatDateFromEpoch(t.updated);
    return `${dateObj.time.hours}:${dateObj.time.minutes} ${dateObj.time.ampm.toUpperCase()} Update`;
  };

  useEffect(() => {
    if (alerts?.length === 0) {
      console.log('No alerts, skipping...');
      skip();
    } else {
      setAlertsReady(true);
      setNumPages(alerts?.length);
    }
  }, [alerts, currentPage, skip]);

  useEffect(() => {
    if (numPages !== null) {
      const perPageDisplayTime = (displayTime * 1000) / numPages;
      alertRotationRef.current = setTimeout(() => {
        setCurrentPage((currentPage === numPages - 1) ? 0 : currentPage + 1);
      }, perPageDisplayTime);
    }

    return () => {
      clearTimeout(alertRotationRef.current);
    };
  }, [currentPage, numPages, displayTime]);

  return (
    <StyledTvAlerts className={className}>
      {/* <button
        onClick={() => setCurrentPage((currentPage === numPages - 1) ? 0 : currentPage + 1)}
      >
        Move Page
      </button> */}
      <TimestampIconWrapper>
        {(alertsReady) && <AlertIcon $alertType={alerts[currentPage].type} name="ui-error" />}
        {(alertsReady) && (
          <Timestamp>
            <TimestampTime>{getTimestampTime(alerts[currentPage])}</TimestampTime>
            <TimestampDate>{getTimestampDate(alerts[currentPage])}</TimestampDate>
          </Timestamp>
        )}
      </TimestampIconWrapper>
      <Message>
        <HtmlContentLoader html={(alertsReady) ? alerts[currentPage].message : 'Getting updates...'} allowRerender />
      </Message>
      {(alertsReady) && <TvPagination numItems={numPages} activeItem={currentPage} />}
      {(alertQrCode) && (
        <AlertQrCodeWrapper>
          <QrCode image={alertQrCode} />
          <div>Scan for More Info</div>
        </AlertQrCodeWrapper>
      )}
    </StyledTvAlerts>
  );
};

TvAlerts.propTypes = {
  className: PropTypes.string,
  displayTime: PropTypes.number,
  skip: PropTypes.func,
  alerts: PropTypes.instanceOf(Array),
};

TvAlerts.defaultProps = {
  className: '',
  displayTime: null,
  alerts: [],
  skip: () => {},
};
