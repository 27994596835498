import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { CalendarBadge } from '@powdr/components/calendar-badge';
import { Link } from '@powdr/components/link';
import { BlockTypes, ThemeProps as Prop } from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import { themeUtil } from '@powdr/utils';

const CPNT = BlockTypes.FLAG_V2;

// styled components
const ContentBlockFlagV2 = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: 50px;
  position: relative;
  width: 100%;

  .calendar-badge {

    .date-day-month-wrapper {
      border: none;
      width: 100%;
      min-width: unset;
      margin-bottom: 0;

      .day-of-week-text {
        transition: all .3s ease-in-out;
        background-color: transparent;
        padding: 5px 0;
      }

      .date-month-wrapper {

        .day-of-month-text {
          color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
          margin-bottom: 10px;
        }
        .month {
          color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
        }
      }
    }
  }

  &:hover:not(.overlay), &:focus:not(.overlay) {
    .inner {
      h3 {
        color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
      }
    }

    .calendar-badge {
      .date-day-month-wrapper {
        .day-of-week-text {
          color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
        }
      }
    }
  }

  &:focus .button {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    outline-offset: 2px;
  }

  .cb-content-wrapper {
    position: relative;
    background: transparent;
    text-align: left;
    width: 100%;
    z-index: 1;


  }

  .cb-title, .cb-copy {
    transition: .3s ease-in-out;
  }

  .cb-title {
    margin-bottom: 10px;
  }

  .cb-copy {
    margin-bottom: 28px;
    display: block;
  }

  .button {
    display: inline-block;
  }

  .cb-content {
    position: relative;
  }
`;

export const FlagV2 = ({
  title, copy, isOverlayEnabled, colorProfile, blockType, ariaLabel,
  buttonEnabled, buttonText, buttonLink, buttonTarget, buttonClick,
  date, endDate,
}) => (
  <ContentBlockFlagV2
    href={buttonLink}
    target={buttonTarget}
    colorProfile={colorProfile}
    onClick={() => buttonClick()}
    aria-label={ariaLabel}
    role="button"
    className={classNames(`content-block-${blockType} `, { overlay: isOverlayEnabled })}
  >
    <CalendarBadge
      type={blockType}
      startDate={date}
      endDate={endDate}
      colorProfile={colorProfile}
    />
    <div className="inner">
      {(title) && <h3 className="cb-title">{title}</h3>}
      {(copy) && <p className="cb-copy">{copy}</p>}
      {(buttonEnabled && buttonText) && (
      <div className="button  with-background" aria-label={ariaLabel}>
        {buttonText}
      </div>
      )}
    </div>
  </ContentBlockFlagV2>
);

FlagV2.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
