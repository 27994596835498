import styled, { css } from 'styled-components';

import { CollapsePanelGroup, PriceRange, WysiwygStyleWrapper } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, Components,
  FontSize,
  FontFamily,
  GlobalLayoutValues,
  DorPlaceStatus,
  StaticProfiles,
} from '@powdr/constants';
import { getDorStatusColor, getFontStyles, themeUtil } from '@powdr/utils';

const THEME_CPNT = Components.MODAL;
const FONTS_CPNT = Components.CONTENT_TEMPLATE;

export const StyledTemplatedContent = styled.div``;

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 7.5%;
  margin: 0 round(nearest, 7.5%, 1px);

  ${Breakpoints.mobile(css`
    margin: 0 3.5%;
    margin: 0 round(nearest, 4.5%, 1px);
  `)}
`;

export const MaxWidthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;
  width: 100%;
  max-width: ${GlobalLayoutValues.DESKTOP.WRAPPER_MAX}px;
  padding-bottom: 90px;
`;

export const RowSection = styled.div`
  display: flex;
  width: 100%;
  gap: 6%;

  ${Breakpoints.mobile(css`
    flex-direction: column-reverse;
    gap: 15px;
  `)}
`;

export const ContentContainer = styled.div`
  width: ${({ $splitContent }) => (($splitContent) ? '50%' : '100%')};

  ${Breakpoints.mobile(css`
    padding-right: 0;
    width: 100%;
  `)}
`;

export const SplitContentContainer = styled.div`
  width: 50%;

  ${Breakpoints.mobile(css`
    width: 100%;
  `)}
`;

export const DynamicTitle = styled.h2`
  color: ${() => themeUtil(Prop.HEADER, THEME_CPNT)};
  margin-bottom: 20px;
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG30, undefined, undefined, FONTS_CPNT, 'title')};

  ${Breakpoints.mobile(css`
    ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.LRG30, undefined, undefined, FONTS_CPNT, 'titleMobile')};
  `)}
`;

export const DynamicSubheader = styled.p`
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.MED30, undefined, undefined, FONTS_CPNT, 'subheader')};
  line-height: 1.2;
  margin-top: 20px;

  ${Breakpoints.mobile(css`
    ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.MED10, undefined, undefined, FONTS_CPNT, 'subheaderMobile')};
  `)}
`;

export const DynamicBody = styled(WysiwygStyleWrapper)`
  margin-top: 20px;
  width: 100%;
`;

export const DynamicBodySplit = styled(WysiwygStyleWrapper)`
  width: 100%;
  margin-top: 20px;

  ${Breakpoints.desktopOnly(css`
    &.mobile-sidebar-content {
      display: none;
    }
  `)}

  ${Breakpoints.mobile(css`
    &.desktop-sidebar-content {
      display: none;
    }
  `)}
`;

export const LocationHoursContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const DynamicContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  line-height: 1.1;

  a {
    transition: color .25s ease-out, background-color .25s ease-out;
    color: ${() => themeUtil(Prop.LINK_TXT, THEME_CPNT)};

    &:hover, &:focus {
      transition: color .25s ease-out, background-color .25s ease-out;
      color: ${() => themeUtil(Prop.LINK_TXT_HOVER, THEME_CPNT)};
    }

    &:focus {
      outline: 2px solid ${() => themeUtil(Prop.LINK_TXT_HOVER, THEME_CPNT)};
      outline-offset: 2px;
    }
  }
`;

export const StatusSection = styled(WysiwygStyleWrapper)`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0;
  }
`;

export const StatusHeader = styled.p`
  font-weight: bold;
`;

export const StatusContent = styled.p`
  color: ${({ $status }) => getDorStatusColor(
    DorPlaceStatus,
    $status,
    THEME_CPNT,
    StaticProfiles.ALERTS,
  )} !important;
`;

export const LocationSection = styled.div`
  display: flex;
  flex-direction: column;

  small {
    font-weight: bold;
  }
`;

export const ContactInfoSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DynamicHours = styled(WysiwygStyleWrapper)`
  p {
    margin-bottom: 0;
  }

  li {
    list-style: none;
    margin: 0 !important;
  }
`;

export const DynamicPrice = styled(PriceRange)`
  font-weight: bold;

  ${Breakpoints.mobile(css`
    margin-bottom: 10px;
  `)}
`;

export const DynamicMenu = styled(WysiwygStyleWrapper)`
  margin-top: 20px;
`;

export const NewsStyleDate = styled.p``;

export const ShortDesc = styled.div`
  margin-bottom: 15px;
`;

export const NewsStyleImage = styled.img``;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  svg {
    fill: ${() => themeUtil(Prop.ICON, THEME_CPNT)};
    color: ${() => themeUtil(Prop.ICON, THEME_CPNT)};
  }
`;

export const StartEndDate = styled.h2`
  color: ${() => themeUtil(Prop.HEADER, THEME_CPNT)};
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};
  margin-left: 15px;
`;

export const TextGallery = styled.div`
  display: flex;
  gap: 10%;
`;

export const Dropdowns = styled(CollapsePanelGroup)``;

export const DropdownsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropdownsWrapperHeader = styled.h2`
  color: ${() => themeUtil(Prop.HEADER, THEME_CPNT)};
  margin-bottom: 15px;
`;

export const TagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 30px;
`;

export const Tag = styled.li`
  padding: 8px 10px;
  color: ${() => themeUtil(Prop.ELEMENT_TXT, THEME_CPNT)};
  background-color: ${() => themeUtil(Prop.ELEMENT, THEME_CPNT)};
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG40, undefined, undefined)};
`;
