/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { useStaticQuery, graphql } from 'gatsby';
import { node } from 'prop-types';
import { useMemo } from 'react';

import {
  HeaderStyles, NavigationStyles, Components,
  NavigationPromoStyleOptions,
  VerticalPosition,
  LinkTargets,
} from '@powdr/constants';
import { isEmpty, sortNavigationByWeight } from '@powdr/utils';

export const useStaticData = () => {
  const {
    site,
    nodeDefaultData,
    paragraphHeaderConfiguration,
    paragraphNavigationConfiguration,
    nestedNavigation,
    paragraphDorFeed,
    paragraphSidebar,
    allNodeGlobalTable,
    allFooterPropertyListJson,
  } = useStaticQuery(
    graphql`
      query {
        site {
          ...SiteMetadataQuery
        }
        nodeDefaultData {
          ...DefaultDataQuery
        }
        paragraphHeaderConfiguration {
          ...HeaderConfigurationQuery
        }
        paragraphNavigationConfiguration {
          ...NavigationConfigurationQuery
        }
        nestedNavigation: taxonomyTermSiteNavigation(field_navigation_link: {eq: "/"}) {
          ...NestedSiteNavigationQuery
        }
        paragraphDorFeed {
          ...DorFeedQuery
        }
        paragraphSidebar {
          ...SidebarQuery
        }
        allNodeGlobalTable {
          ...AllGlobalTableQuery
        }
        allFooterPropertyListJson {
          nodes {
            title
            iconName
            height
            link
          }
        }
      }
    `,
  );

  const filteredDefaultDataComponents = nodeDefaultData?.relationships.defaultComponents
    .filter((component) => !isEmpty(component));
  const filteredGlobalContent = allNodeGlobalTable.nodes.filter((node) => node?.html?.value);

  // TODO: maybe make this utility if other components are doing something similar
  const findComponent = (key) => filteredDefaultDataComponents
    ?.filter((component) => component.typeName === key) || [];

  const navigationObject = useMemo(() => ({
    ...nestedNavigation,
    childPages: sortNavigationByWeight(
      nestedNavigation?.relationships?.pages?.sort((a, b) => a.weight - b.weight) || [],
    ),
  }), [nestedNavigation]);

  return {
    ...site.siteMetadata,
    defaultMeta: {
      metaImage: nodeDefaultData?.relationships?.defaultMetaImage?.relationships?.field_image.gatsbyImage || null,
      metaDescription: nodeDefaultData?.defaultMetaDescription || null,
      metaTitle: nodeDefaultData?.defaultMetaTitle || null,
    },
    defaultSeason: nodeDefaultData?.defaultSeason?.toLowerCase(),
    headerConfig: {
      ...paragraphHeaderConfiguration,
      headerStyle: (paragraphHeaderConfiguration?.navigationOpacityPercentage < 100)
        ? HeaderStyles.TRANSLUCENT
        : HeaderStyles.SOLID,
      navigationDropdownStyle:
        paragraphHeaderConfiguration?.navigationDropdownStyle
        || NavigationStyles.FULL_WIDTH,
      isRightAlignedNav: paragraphHeaderConfiguration?.isRightAlignedNav || false,
      bannerLinkGroups: (paragraphHeaderConfiguration?.relationships?.bannerLinkGroups
        && paragraphHeaderConfiguration?.relationships?.bannerLinkGroups?.length > 0)
        ? paragraphHeaderConfiguration?.relationships?.bannerLinkGroups
        : null,
      promotions: (paragraphHeaderConfiguration?.relationships?.promotions)
        ? paragraphHeaderConfiguration.relationships.promotions
        : null,
      isDorFeedEnabled: paragraphHeaderConfiguration?.isDorFeedEnabled,
      feedTempHide: paragraphHeaderConfiguration?.hideDorFeedSection === 1,
      feedSnowfallHide: paragraphHeaderConfiguration?.hideDorFeedSection === 2,
      seasonalityPlacement: (paragraphHeaderConfiguration?.isSeasonalityEnabled && paragraphHeaderConfiguration?.seasonalityPlacement) ? paragraphHeaderConfiguration?.seasonalityPlacement : '',
      navigationComponents: (paragraphHeaderConfiguration?.relationships?.navigationComponents)
        ? paragraphHeaderConfiguration.relationships.navigationComponents
        : null,
      dorComponents: (paragraphHeaderConfiguration?.relationships?.dorComponents)
        ? paragraphHeaderConfiguration.relationships.dorComponents
        : null,
    },
    dorDrawer: {
      feedSections: (paragraphDorFeed?.relationships?.feedSections) ? [
        ...paragraphDorFeed.relationships.feedSections,
      ] : null,
      promotionsPlacement: (paragraphDorFeed?.promotionsPlacement)
        ? paragraphDorFeed?.promotionsPlacement
        : VerticalPosition.BOTTOM,
      promotionList: (paragraphDorFeed?.relationships?.dorPromotions)
        ? paragraphDorFeed.relationships.dorPromotions
        : null,
    },
    sidebar: (paragraphSidebar?.relationships?.sidebarItems)
      ? paragraphSidebar.relationships.sidebarItems
      : null,
    /* socialIcons: {
      ...allParagraphSocialIcon,
    }, */
    navigationDrawer: {
      promotions: {
        style: (paragraphNavigationConfiguration?.promoStyle)
          ? paragraphNavigationConfiguration.promoStyle
          : NavigationPromoStyleOptions.CIRCULAR,
        items: (paragraphNavigationConfiguration?.relationships?.promotions?.relationships?.promotion?.length > 0)
          ? paragraphNavigationConfiguration?.relationships?.promotions?.relationships?.promotion
          : null,
      },
      loginLink: {
        href: paragraphNavigationConfiguration?.loginLink || null,
        target: paragraphNavigationConfiguration?.target || LinkTargets.BLANK,
      },
      navigation: { ...navigationObject },
    },
    footerComponents: findComponent(Components.FOOTER_COMPONENTS)?.[0]?.relationships?.components,
    globalContent: filteredGlobalContent,
    chatComponent: {
      hasChatComponent: !!(
        !isEmpty(findComponent(Components.CHAT_SATISFI)?.[0])
        || !isEmpty(findComponent(Components.CHAT_TRACKHS)?.[0])
      ),
      chatSatisfi: findComponent(Components.CHAT_SATISFI)?.[0],
      chatTrackhs: findComponent(Components.CHAT_TRACKHS)?.[0],
    },
    mobileDrawer: {
      drawerButton: findComponent(Components.MOBILE_DRAWER_BUTTON)?.[0],
    },
    socialIcons: findComponent(Components.FOOTER_SOCIALS),
    footerPropertyList: [...allFooterPropertyListJson.nodes],
  };
};
