/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
import {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DorMeasurementFieldNames, GroomStatus, LiftsTrailsMeasurementTypes,
  LiftTrailStatus, TrailsPropertyKeys,
  TrailTypes,
} from '@powdr/constants';
import { getTrails } from '@powdr/stores';
import { filterLiftTrailItemsBySeason } from '@powdr/utils';

export const useTrailsMeasurements = (
  ignoreTrailTypes,
  ignoreGroomStatus,
  includeInOpenCount,
  measurementSpecification,
  season,
) => {
  const dispatch = useDispatch();
  const useTrailsData = useSelector((state) => state.trails);
  const { data } = useTrailsData;
  const [trailsData, setTrailsData] = useState(null);

  const filterTrails = useCallback(
    (
      d,
      checkOpen,
      checkGroomStatus,
      trailIgnoreList,
      groomStatusIgnoreList,
      includeInOpen,
    ) => d
      .filter((t) => t.include === true)
      .filter((t) => !trailIgnoreList?.includes(t.type))
      .filter((t) => ((checkGroomStatus)
        ? !groomStatusIgnoreList?.includes(t.groom_status)
        : true))
      .filter((t) => (
        (checkOpen)
          ? (t.status === LiftTrailStatus.OPEN || includeInOpen.includes(t.status))
          : true
      )),
    [],
  );

  const propertyReduceAdder = useCallback(
    (d, property) => d
      .reduce((accum, current) => {
        if (current?.segments?.length > 0) {
          return accum + propertyReduceAdder(current?.segments, property);
        }

        return accum + current.properties[property];
      }, 0),
    [],
  );

  const getMeasurements = useCallback(
    () => {
      switch (measurementSpecification) {
        case LiftsTrailsMeasurementTypes.MILES_OF_SKIING:
        case LiftsTrailsMeasurementTypes.ACRES_OF_SKIING:
          return {
            [DorMeasurementFieldNames.OPEN]: Math.round(propertyReduceAdder(
              filterLiftTrailItemsBySeason(
                filterTrails(
                  data,
                  true,
                  false,
                  ignoreTrailTypes,
                  ignoreGroomStatus,
                  includeInOpenCount,
                ),
                season,
              ),
              TrailsPropertyKeys[measurementSpecification],
            )),
            [DorMeasurementFieldNames.TOTAL]: Math.round(propertyReduceAdder(
              filterLiftTrailItemsBySeason(
                filterTrails(
                  data,
                  false,
                  false,
                  ignoreTrailTypes,
                  ignoreGroomStatus,
                  includeInOpenCount,
                ),
                season,
              ),
              TrailsPropertyKeys[measurementSpecification],
            )),
          };
        case LiftsTrailsMeasurementTypes.KILLINGTON_GROOMED:
          const trailIgnoreList = [
            TrailTypes.CROSS_COUNTRY_TRAIL,
            TrailTypes.MOUNTAIN_BIKE_TRAIL,
            TrailTypes.HIKING_TRAIL,
            TrailTypes.TERRAIN_PARK_TRAIL,
            TrailTypes.UPHILL_TRAIL,
            TrailTypes.FAT_BIKE_TRAIL,
            TrailTypes.TUBING_TRAIL,
            TrailTypes.SNOWSHOE,
          ];

          return {
            [DorMeasurementFieldNames.OPEN]: filterLiftTrailItemsBySeason(
              filterTrails(
                data,
                true,
                true,
                trailIgnoreList,
                [
                  GroomStatus.GROOMED_AM,
                  GroomStatus.GROOMED_PM,
                  GroomStatus.NOT_GROOMED,
                ],
              ),
              season,
            ).length,
            [DorMeasurementFieldNames.TOTAL]: filterLiftTrailItemsBySeason(
              filterTrails(
                data,
                true,
                false,
                trailIgnoreList,
                [],
              ),
              season,
            ).length,
          };
        default:
          return {
            [DorMeasurementFieldNames.OPEN]: filterLiftTrailItemsBySeason(
              filterTrails(
                data,
                true,
                false,
                ignoreTrailTypes,
                ignoreGroomStatus,
                includeInOpenCount,
              ),
              season,
            ).length,
            [DorMeasurementFieldNames.TOTAL]: filterLiftTrailItemsBySeason(
              filterTrails(
                data,
                false,
                false,
                ignoreTrailTypes,
                ignoreGroomStatus,
                includeInOpenCount,
              ),
              season,
            ).length,
          };
      }
    },
    [
      data,
      filterTrails,
      measurementSpecification,
      propertyReduceAdder,
      season,
      ignoreTrailTypes,
      ignoreGroomStatus,
      includeInOpenCount,
    ],
  );

  useEffect(() => {
    dispatch(getTrails());
  }, [dispatch]);

  useEffect(() => {
    if (data && data?.length > 0) {
      setTrailsData(getMeasurements());
    }
  }, [data, getMeasurements]);

  return trailsData;
};
