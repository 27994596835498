import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon } from '@powdr/components';
import { FontFamily } from '@powdr/constants';
import { getFontStyles } from '@powdr/utils';

const StyledIconDetails = styled.div`
  display: flex;
  align-items: center;

  ${({ $hyphenate }) => (($hyphenate) ? `
    gap: 20px;
    justify-content: center;
  ` : `
    justify-content: space-evenly;
  `)}
`;

export const IconBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  ${({ $hyphenate }) => (!$hyphenate) && 'flex: 0 1 100%;'}
`;

export const IconBlockIcon = styled(Icon)`
  height: 50px;
  width: 50px;
`;

export const LargeHyphen = styled.div`
  height: 5px;
  width: 40px;
  background-color: ${({ $color }) => $color};
`;

export const IconBlockLabel = styled.div`
  color: ${({ $color }) => $color};
  ${() => getFontStyles(FontFamily.BASE_FONT, '18px', undefined, undefined)};
`;

export const IconDetails = ({
  className,
  iconData,
  hyphenateItems,
  iconColor,
  textColor,
}) => (
  <StyledIconDetails className={className} $hyphenate={hyphenateItems}>
    {(iconData) && iconData.map((i, idx) => (
      <>
        <IconBlock $hyphenate={hyphenateItems}>
          <IconBlockIcon name={i?.iconName} $color={iconColor} />
          <IconBlockLabel $color={textColor}>{i?.title}</IconBlockLabel>
        </IconBlock>
        {(hyphenateItems && idx !== iconData.length - 1)
          && <LargeHyphen $color={textColor} />}
      </>
    ))}
  </StyledIconDetails>
);

IconDetails.propTypes = {
  className: PropTypes.string,
  iconData: PropTypes.arrayOf(PropTypes.shape({})),
  hyphenateItems: PropTypes.bool,
  iconColor: PropTypes.string,
  textColor: PropTypes.string,
};

IconDetails.defaultProps = {
  className: '',
  iconData: null,
  hyphenateItems: false,
  iconColor: null,
  textColor: null,
};
